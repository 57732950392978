<template>
  <div id="app">
    <router-view v-if="isRouterAlive"/>
  </div>
</template>

<script>
import { wxShareInit } from '@/utils/wxshare'
import { isWeChat } from '@/utils'
import { mapGetters } from 'vuex'

export default {
  name: 'App',
  provide () {
    return {
      reload: this.reload
    }
  },
  data () {
    return {
      isRouterAlive: true
    }
  },
  computed: {
    ...mapGetters([
      'name',
      'token',
      'userow'
    ])
  },
  created () {
    window.addEventListener('load', () => {
      if (this.$store.getters.name) {
        this.$store.dispatch('GetUserProfile', this.$store.getters.name)
        this.$store.dispatch('GetFrontUserInfoByUserID', { UserID: this.$store.getters.name })
      }
    })
    window.addEventListener('mousedown', () => {
      if (this.$store.getters.token) {
        this.$store
          .dispatch('GetUserProfileByToken', this.$store.getters.token)
          .then(() => {}).catch(err => {
            console.log(err)
            window.localStorage.clear()
            this.$store.dispatch('clearToken')
            this.$router.replace('/cn/BTCInfo')
          })
      }
    })
  },
  updated () {
    if (isWeChat()) {
      wxShareInit(this)
    }
  },
  methods: {
    reload () {
      this.isRouterAlive = false
      this.$nextTick(function () {
        this.isRouterAlive = true
      })
    }
  }
}
</script>

<style>
#app {
  min-width: 1248px!important;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
/*
::-webkit-scrollbar {
  display: none; /* Chrome Safari
}
*/
</style>
