<template>
  <div style="display: flex;flex-direction:column;justify-content: center;align-items: center;  background-color: rgb(230,230,230);">
    <div class="pre-footer">
        <b-row>
          <b-col cols="4" style="text-align:left">
            <div>
              <h2>必孚服务</h2>
            </div>
            <div class="footer-service" style="font-size:14px;color: #535353; cursor: pointer;">
              <li v-for="(item, index) in tabs" :key="index" tabindex="1" @click="handleClickTab(item, index)">
                <a>{{ item.text }}</a>
              </li>
            </div>
          </b-col>
          <b-col cols="4" style="text-align:left;padding-left: 80px">
            <div>
              <h2>法律条款</h2>
            </div>
            <div class="footer-service">
              <div  class="hand" @click="handleTerms">服务条款</div>
              <div  class="hand" @click="handlePrivacyPolicy">免责声明</div>
            </div>
            <div class="follow-us">
              <h2 class="hand" @click="handleContactUs">联系我们</h2>
              <div style="margin-top: 20px">
                <div>联系我们</div>
                <a href="mailto:contact@beeftochina.com">contact@beeftochina.com</a>
              </div>
            </div>
          </b-col>
          <b-col cols="4" style="display: flex;flex-direction: column;justify-content: flex-start;padding: 0;align-items: flex-end">
            <div style="width: 122px;text-align: center" class="hand">
              <h2 style="margin-top: 30px">必孚App</h2>
            </div>
            <div class="contact-us">
              <div>
                <img style="width: 122px;height: 120px;margin-top: 10px" src="@/assets/image/cn/2code.png" alt="">
                <div style="font-size: 14px;color: #535353">扫描下载</div>
              </div>
            </div>
          </b-col>
      </b-row>
    </div>
    <div class="footer">
      <b-row>
        <b-col class="col-md-12 col-sm-12 padding-top-10" style="text-align: center">
          Copyright © 2014-2025 Beef to China. All Rights Reserved.<br>
          <a href="http://beian.miit.gov.cn" target="_blank">蜀ICP备19014942号-1</a>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CNFooter',
  data () {
    return {
      tabs: [
        { text: '首页', href: '/cn/Homepage' },
        // { text: '必孚可视', href: '/cn/BTCEyes' },
        { text: '必孚信息', href: '/cn/BTCInfo' },
        { text: '必孚数据', href: '/cn/BTCData' },
        { text: '必孚行情', href: '/cn/BTCPrice' },
        { text: '必孚工厂', href: '/cn/BTCEst' },
        { text: '必孚云展', href: '/cn/BTCTrend' },
        { text: '必孚商户', href: '/cn/BTCUser' },
        { text: '必孚App', href: '/cn/BTCApp' }
      ],
      url: `${process.env.VUE_APP_STATIC}CnPlantIcon/pdf/BTCMembership.pdf`
    }
  },
  methods: {
    handleClickTab (item, index) {
      this.$router.push(item.href)
      this.current = index
      sessionStorage.setItem('navindex', index)
    },
    handleFollowUs (url) {
      window.open(url)
    },
    handleContactUs () {
      this.$router.push('/cn/ContactUs')
    },
    handleTerms () {
      this.$router.push('/cn/Terms')
    },
    handlePrivacyPolicy () {
      this.$router.push('/cn/PrivacyPolicy')
    },
    read (url) {
      window.open(url)
    }
  }
}
</script>

<style lang="scss" scoped>
li{
  list-style: none;
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: 0;
  margin-left: 0;
}

.pre-footer {
  height: 321px;
  background-position: 50% 50%;
  background-size: 100%;
  background-repeat: no-repeat;
  background-image:url(../../../../assets/image/footer/background-image.png);
  color: #b0b0b0;
  width: 960px;
}
.pre-footer .container {
  padding-top: 45px;
}
.pre-footer a {
  color: rgb(83, 83, 83);
}
.pre-footer h2,
.ecommerce .pre-footer h2 {
  margin-top: 30px;
  font-weight: bold;
  font-size: 21px;
  color: #535353;
}
.pre-footer p {
  margin-bottom: 20px;
}
.pre-footer li {
  margin-top: 8px;
}
.footer-service {
  margin-top: 20px;
  font-weight: 500;
  font-size: 14px!important;
}
.footer-service div{
  margin-top: 10px;
  color: #535353;
}
.follow-us {
  margin-top: 60px;
}
.follow-us > div{
  font-size: 14px;
  width: 240px;
  display: flex;
  justify-content: flex-start;
}

.follow-us > div > a{
  margin-left: 10px;
}

.contact-us {
  font-weight: 500;
  display: flex;
  justify-content: flex-end;
  font-size: 15px!important;
}

.contact-us > div{
  width: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contact-us > div >  img{
  margin-bottom: 10px;
  width: 74px;
  height: 73px;
}

/* footer */
.footer {
  width: 100%;
  background: #272626;
  color: #fff;
  font-size: 12px;
  padding: 15px 0;
}
.footer-light {
  background: #F9F9F9;
  color: #3e4d5c;
}
.footer-gray {
  background: #4A5866;
}
.footer a {
  color: #fff;
  text-decoration: underline;
}
.footer a:hover {
  text-decoration: none;
}
.footer-light a {
  color: inherit;
}
.footer .padding-top-10 {
  opacity: 0.5;
}
.footer .list-inline > li:last-child {
  padding-right: 0;
}
.footer ul {
  margin: 0;
  padding: 0;
}
.footer ul.social-footer {
  font-size: 18px;
  padding-top: 5px;
}
.footer ul.social-footer a {
  opacity: 0.5;
}
.footer ul.social-footer a:hover {
  opacity: 1;
}
</style>
